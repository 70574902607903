import Vue from 'vue'
import Vuex from 'vuex'


import state from './state';
import mutations from './mutations';
import actions from './actions';


import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)



export default new Vuex.Store({
  state,
  actions,
  mutations,
  plugins:[createPersistedState({
    storage:window.localStorage,
    reducer(val){
      return {
        userInfo:val.userInfo,
        aesKey:val.aesKey
      }
    }
  }),
  createPersistedState({
    storage:window.sessionStorage,
    reducer(ses)
    {
      return{
        hastoastonDutyMark:ses.hastoastonDutyMark,
        style:ses.style,
        breadcrumb:ses.breadcrumb,
      }
    }
  })
]
})




// state: {
//   info:''
// },
// mutations: {
// updataUserInfo(state,info){
// state.info=info;
// console.log("mutation改后的state.info:",state.info);
// }



// },
// actions: {

// },
// modules: {
  
// }
