<template>
  <div class="empty-wrap-child">
    <!--  :style="{position: 'absolute', top: positionConfig.top, left: positionConfig.left}" -->
    <img src="../../assets/img/fontimg/empty.png" alt="" />
    <p>
      <slot><span>当前暂无数据哦~</span></slot>
    </p>
  </div>
</template>

<script>
export default {
  created() {
    // console.log("这是空页面+++++++++++");
  },
  mounted() {
    // console.log("这是空页面+++++++++++");
  },
  props: [],
};
</script>

<style scoped>
.empty-wrap-child {
  width: 300px;
  height: 300px;
  /* background-color: red; */
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
}
</style>