//lodash可用于提取嵌套对象中的指定对象失败定义为null







// commonParam对象
//接口文档要求每次请求有一个平级的公共参数
//里面的requestCode/no要在调用时自增
// const commonParam = function () {
//   this.commonParam = {
//     platform: 'pc', //类型 String
//     requestNo: 10001, //类型 Integer
//   };
//   //   this.requestNo = number;
//   return commonParam;
// };
export default{
   install(Vue){
     

 
    Vue.prototype.commonParam = (
      function(){
      // this.commonParam = new Object;
      //登录接口重置requestNo值再用，其余都能用
      // console.log(window.localStorage.getItem('requetNo'),'requestno')

      // console.log(requestNo,'requestno中间')
      // if(!localStorage['requestNo']){console.log(window.localstorage['requestNo']);localStorage['requestNo']=10001;}
      // if(localStorage['requestNo']!=10001||localStorage['requestNo']!=null)
    //   {
    //    requestNo = localStorage['requestNo']+1;
    //    window.localStorage.setItem('requestNo',requestNo);
    // }

    let requestNo = window.localStorage.getItem('requetNo')?window.localStorage.getItem('requetNo'):0; 
    // console.log(requestNo,'从ls取到的requestNo')
// console.log(window.localStorage.getItem('requetNo'),'requestnoafter')
      let commonParam = {
        platform:'pc',
        requestNo,
      }
    return function(){
      requestNo = requestNo + 1;
      commonParam.requestNo =requestNo;
      localStorage.setItem('requestNo',requestNo);
      return commonParam;
    }
    
    
    })();
  }
}