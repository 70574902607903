<template>
  <div>
    <Modal
      v-model="valueShow"
      title="自定义宽度"
      width="570"
      :mask-closable="false"
      :styles="{ top: '160px' }"
      @on-visible-change="showChange"
    >
      <template #header>
        <p style="font-weight: bold; font-size: 14px">医生详情</p></template
      >
      <div class="doctorDetail-wrap">
        <div class="doctorDetail-top">
          <div><img :src="doctorDetail.avatarUrl" alt="" /></div>
          <div>
            <div>
              <span style="font-size: 18px; font-weight: bold">{{
                doctorDetail.name
              }}</span>
              <span style="font-size: 14px; margin-left: 15px">{{
                doctorDetail.doctorLevelName
              }}</span>
            </div>
            <div>
              <i
                class="iconfont icon-icon_chat_text_location"
                v-if="doctorDetail.hospitalName != ''"
              ></i
              >{{ doctorDetail.hospitalName }}
            </div>
          </div>
        </div>
        <div class="doctorDetail-intro">
          <p>简介</p>
          <p>
            {{
              doctorDetail.intro == '' || doctorDetail.intro == null
                ? '暂无简介'
                : doctorDetail.intro
            }}
          </p>
        </div>
        <div class="doctorDetail-remark">
          <p>擅长</p>
          <p>
            {{
              doctorDetail.remark == '' || doctorDetail.remark == null
                ? '暂无填写擅长'
                : doctorDetail.remark
            }}
          </p>
        </div>
        <!-- {{doctorDetail}} -->
      </div>
      <template #footer>
        <div class="footerModal" v-show="bottomShow">
          <button @click="close()" style="cursor: pointer">删除好友</button>
          <button @click="deleteOk()" style="cursor: pointer">发消息</button>
        </div>
        <div v-show="!bottomShow"></div>
      </template>
    </Modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //   show: false,
      //   doctorDetail: {},
    };
  },
  methods: {
    // deleteOk() {
    //   this.$emit('ok');
    //   // this.$emit("update:showValue", false);
    // },
    close() {
      //   this.$emit('cancel');
      // this.$emit("update:showValue", false);
      this.$emit('delete');
    },
    showChange(val) {
      if (val == false) {
        this.$emit('cancel');
      }
    },
    getDoctorDetail() {
      console.log(this.id);
    },
  },
  props: {
    showValue: {
      default: false,
    },
    id: {
      required: true,
    },
    doctorDetail: {
      required: true,
    },
    bottomShow: {
      default: true,
    },
  },
  computed: {
    valueShow: {
      get() {
        return this.showValue;
      },
      set(value) {
        this.$emit('update:showValue', value);
      },
    },
  },
  watch: {
    // show: {
    //   handler(newV) {
    //     this.$emit("change", newV);
    //   },
    // },
  },
};
</script>

<style scoped>
.doctorDetail-wrap {
  width: 100%;
}
.doctorDetail-top {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.doctorDetail-top > div:nth-of-type(1) {
}
.doctorDetail-top > div:nth-of-type(1) > img {
  width: 78px;
  height: 78px;
  border: 1px solid #e6e6e6;
  border-radius: 50%;
  opacity: 1;
}
.doctorDetail-top > div:nth-of-type(2) > div:nth-of-type(1) {
  padding-top: 15px;
  margin-left: 25px;
}
.doctorDetail-top > div:nth-of-type(2) > div:nth-of-type(2) {
  /* font-size: 14px; */
  margin-left: 25px;
  margin-top: 5px;
}
.doctorDetail-intro {
  margin-top: 30px;
  font-size: 14px;
}
.doctorDetail-intro > p:nth-of-type(2) {
  width: 100%;
  height: 140px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  padding: 10px 10px 0 10px;
  margin-top: 5px;
  /*  */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}
.doctorDetail-remark {
  margin-top: 30px;
  font-size: 14px;
}
.doctorDetail-remark > p:nth-of-type(2) {
  width: 100%;
  height: 140px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  padding: 10px 10px 0 10px;
  margin-top: 5px;
  /*  */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}
.footerModal {
  margin-right: 15px;
}
.footerModal button:nth-of-type(1) {
  width: 111px;
  height: 32px;
  background: white;
  border: 1px solid #ee020e;
  opacity: 1;
  border-radius: 20px;
  color: #ee020e;
}
.footerModal button:nth-of-type(2) {
  width: 111px;
  height: 32px;
  background: #00a8e3;
  border: 1px solid #00a8e3;
  box-shadow: 0px 3px 6px rgba(123, 123, 123, 0.16);
  opacity: 1;
  border-radius: 20px;
  color: white;
}
</style>