const state = {
  style: {//面包屑、侧边栏、展开列表保持一致
    chooseitemvalue: '', //是侧边栏选项，选中的选项必须在展开列表里面
    unfold: [],//例如unfold:['管理后台'],
  },
  userInfo: {
    id: 0,
    name: '',
    token:'',
      // '888888',
      // '666666', //系统管理员
    // '77777',//专家医生
    roleCode: 1,
    menuList: null,
    //  [
    //   {
    //     //菜单id
    //     id: 1,
    //     //菜单编号
    //     menu_code: '001',
    //     //菜单名称
    //     menu_name: '首页',
    //     //菜单类型(1:一级菜单,2:二级菜单,3:三级菜单,4:四级菜单)
    //     menu_type: 1,
    //     //上级菜单编号
    //     parent_menu_code: 0,
    //   },
    //   {
    //     id: 2,
    //     menu_code: '002',
    //     menu_name: '2案例管理',
    //     menu_type: 1,
    //     parent_menu_code: 0,
    //   },
    //   {
    //     //菜单id
    //     id: 3,
    //     //菜单编号
    //     menu_code: '003',
    //     //菜单名称
    //     menu_name: '3首页333',
    //     //菜单类型(1:一级菜单,2:二级菜单,3:三级菜单,4:四级菜单)
    //     menu_type: 1,
    //     //上级菜单编号
    //     parent_menu_code: '001',
    //   },
    //   {
    //     //菜单id
    //     id: 4,
    //     //菜单编号
    //     menu_code: '00133333',
    //     //菜单名称
    //     menu_name: '444首页',
    //     //菜单类型(1:一级菜单,2:二级菜单,3:三级菜单,4:四级菜单)
    //     menu_type: 1,
    //     //上级菜单编号
    //     parent_menu_code: '001',
    //   },
    //   {
    //     //菜单id
    //     id: 5,
    //     //菜单编号
    //     menu_code: '009',
    //     //菜单名称
    //     menu_name: '555首页',
    //     //菜单类型(1:一级菜单,2:二级菜单,3:三级菜单,4:四级菜单)
    //     menu_type: 1,
    //     //上级菜单编号
    //     parent_menu_code: null,
    //   },
    // ],
  },
  aesKey: '',
  hastoastonDutyMark: false,
  breadcrumb: [
    // {
    //   level: 1,//level说明：1-10是侧边栏菜单，请一级菜单level全写1，二级菜单level全写2.右上角下拉框是999。
    //   value: '首页',
    //   name:'',
    //    params:'',
    // },
    // {},
  ], //头部导航,
  // 路由跳转携带非路由参数(页面内公共参数)
  pathParams: {
    // 对象键名为页面name，paramsRe为你所需要传递的页面公共参数
    caseDetail: {
      fromPath: "",
      paramsRe: {
        tabs: "",
        index:"",
      }
    },
    doctorManage:{
      fromPath: "",
      paramsRe: {
        headerActive: "",
      }
    },
    teamlist:{
      fromPath: "",
      paramsRe: {
        activetype: "",
      }
    },
    opManage:{
      fromPath: "",
      paramsRe:{
        
      }
    },
    doctorWork:{
      fromPath: "",
      paramsRe:{
        
      }
    },
  },
  common:{
    platform: 'pc',
    requestNo: 10001,
    requestCode: 20001,
  },
  wsobj:{
    accountId:null,
    avatarUrl:'',
  },//ws获取的accountId等信息
};
export default state;
