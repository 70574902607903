// import store from "../../store";
// import axios from "axios";
// import globalurl from "../../plugins/globalConfig/globalurl";
import Vue from "vue";
import '../tools/commonFun'

export default {
    install() {
        Vue.prototype.$knowledgeType = (val) => {
            let list = [
                { text: "知识分享", value: 5 },
                { text: "精彩术式", value: 1 },
                { text: "行业资讯", value: 2 },
                { text: "产品资讯", value: 3 },
                { text: "前沿“镜”向", value: 4 },
                { text: "视频点播", value: 6 },
            ];
            let returnValue = "";
            list.forEach((value) => {
                // console.log(val);
                if (val == value.value) {
                    returnValue = value.text;
                    console.log(value.text)
                }
            })
            return returnValue
        }

      /*  Vue.prototype.$getKnowledgeSubjectList = () => {
            let { serverurl } = globalurl
            let commonParam = Vue.prototype.$commonParams;
            console.log(commonParam());
            axios({
                method: "POST",
                url: serverurl + "/category/queryAllSubjectCategory",
                data: {
                    commonParam: commonParam(),
                    token: store.state.userInfo.token,
                    accountId: store.state.userInfo.accountId,
                },
            }).then((res) => {
                console.log(res.data.data);
                let list = []
                let useList = []
                if (res.data.resultFlag == true) {
                    list  = res.data.data.allSubjectCategoryList
                    list.forEach(curr => {
                        let obj = {
                            name: curr.categoryName,
                            value: curr.id
                        }
                        useList.push(obj)
                    })

                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'error',
                        customClass: 'mzindex'
                    })
                }
                return list
            });
        }*/

    }
}



