// import state from './state';
const mutations = {
  updatewsobj(state, newval) {
    state.wsobj = newval;
  },
  // addBreadCrumb(state,newVal){
  //   state.breadcrumb = state.breadcrumb.concat(newVal);
  // },
  // popBreadCrumb(state){
  //   state.breadcrumb = (state.breadcrumb).pop();
  // },
  updatechooseitemvalue(state, newval) {
    state.style.chooseitemvalue = newval;
  },
  updateBreadCrumb(state, newArr) {
    // console.log('newArr,mutationhere mutation', newArr);
    state.breadcrumb = newArr;
  },
  updateUnfold(state, newArr) {
    state.style.unfold = newArr;
    // console.log(state.style.unfold, 'unfold在vuex里面改过以后')
  },
  clearBreadCrumb(state) {
    state.breadcrumb = [];
    console.log(state.breadcrumb, '看看清成功了么')
  },
  updateUserInfo(state, info) {
    (state.userInfo = info),
      console.log('mutation改后的state.info:', state.userInfo);
  },
  updatePartofuserInfo(state, value) {//value数组跟state的userinfo数组里面有交集的都给改了
    //如果value里面有，二userinfo里面没有，也给他加上。
    //我需要深拷贝
    // console.log(state.userInfo,'value',value,'看下value');
    // state.userInfo = Object.assign(state.userInfo,value);
    // console.log()
    // console.log(state.userInfo,'state.u',value,'value')
    Object.keys(state.userInfo).forEach((item) => {
      if (Reflect.get(value, item)) {
        if (value[item] != state.userInfo[item]) {
          state.userInfo[item] = value[item];
        }
      }
    })
    Object.keys(value).forEach((item) => {

      if (Reflect.get(value, item) && (Reflect.get(state.userInfo, item) === undefined)) {
        // state.userInfo[item] = value[item];
        console.log(state.userInfo, '!!!!!!!!!!!!!!!!!!!!state.userInfo')

      }
    })
    console.log(state.userInfo, '现在的userinfo是啥')
  },
  updateAesKey(state, aesKey) {
    state.aesKey = aesKey;
  },
  add(state) {
    sessionStorage.setItem('token', state.userInfo.rememberToken);
    state.token = state.userInfo.rememberToken;
  },
  remove(state, token) {
    state.userInfo = '';
    sessionStorage.removeItem('token', token);
    state.token = token; //?
    console.log('清除state中的userinfo');
  },
  quit(state) {
    let palmInfo = state.userInfo.hospitalCode;
    if (palmInfo == undefined || palmInfo == null) {
      palmInfo = 0;
    }
    console.log(palmInfo);
    state.style.chooseitemvalue = '';
    state.style.unfold = [];
    state.breadcrumb = [];
    state.userInfo = '';
    state.aesKey = '';
    state.hastoastonDutyMark = false;
    state.common = {
      platform: 'pc',
      requestNo: 10001,
      requestCode: 20001,
    };
    state.wsobj = {
      accountId: null,
    };

    window.sessionStorage.clear();
    window.localStorage.clear();
    localStorage.setItem("plamInfo", JSON.stringify(palmInfo));
    return 1;
  },
  //路由跳转时页面传递公共参数所需要调用的函数
  changePathParams(state, params) {
    console.log(state, params);
    // let paramsObj=state.pathParams;
    let fromName = params.from;
    let toName = params.to;
    console.log(toName);
    state.pathParams[toName].fromPath = fromName;
    state.pathParams[toName].paramsRe = { ...params.paramsIn };
    console.log(state.pathParams[toName]);
    setTimeout(() => {
      state.pathParams[toName].fromPath = "";
      console.log(state.pathParams);
    }, 2000);
  },
  commonParamsChange(state) {
    let common = state.common;
    common.requestNo += 1;
    // console.log(common.requestNo);
    return common;
  }
};

export default mutations;
