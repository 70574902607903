import messageChat from './meetingChat';
import globalUrl from "../../plugins/globalConfig/globalurl.js";
let { wsurl } = globalUrl
function createAction() {
    let mChat = null;
    this.getMessage;
    this.callback;
    this.callbackChild;
    this.createWS = (id, token) => {
        console.log(id, token)
        console.log("这是createWS类的内部",wsurl +
        '/chatsocket?token=' +
        token +
        '&source=pc',
        id,//id
        token,//token
        'pc')
        mChat = new messageChat.meetingChat();
        mChat.onHandlerMsg = _onHandlerMsg;
        //number:"13735480072"

        // mChat.createWebSocket(
        //     wsurl +
        //     '/chatsocket?token=' +
        //     '888888' +
        //     '&source=pc',
        //     73,//id
        //     '888888',//token
        //     'pc'
        // );
        //number:"15300000001"

        // mChat.createWebSocket(
        //     wsurl +
        //     '/chatsocket?token=' +
        //     '777777' +
        //     '&source=pc',
        //     107,//id
        //     '777777',//token
        //     'pc'
        // );

        // mChat.createWebSocket(
        //     wsurl +
        //     '/chatsocket?token=' +
        //     token +
        //     '&source=pc',
        //     id,//id
        //     token,//token
        //     'pc'
        // );
        mChat.createWebSocket(
            wsurl +
            '?token=' +
            token +
            '&source=pc',
            id,//id
            token,//token
            'pc'
        );
    }
    let _onHandlerMsg = (res) => {
        this.getMessage(res);
        if (this.callback != null) {
            this.callback(res);
        }
        if (this.callbackChild != null) {
            this.callbackChild(res);
        }
    }
    this.sendMsg = (sendName, value) => {
        console.log("createWS发送消息");
        console.log(sendName)
        mChat[sendName](value);
    }
    this.closeWS = () => {
        mChat.close();
    }
}
export default {
    createAction
};