import Vue from "vue";
import EmptyPage from "../../components/common/emptyPage.vue";
import SureModal from "../../components/common/deleteModal.vue";
import DoctorDetail from "../../components/common/doctor_detail.vue";
import ElisModalSmall from "../../components/common/ElisModalSmall.vue";
import ElisButton from "../../components/common/ElisButton.vue";

Vue.component("EmptyPage", EmptyPage);
Vue.component("SureModal", SureModal);
Vue.component("DoctorDetail", DoctorDetail);
Vue.component("ElisModalSmall", ElisModalSmall);
Vue.component("ElisButton", ElisButton);
