//手术医生相关页面
const child1 = [
    {
        path: 'home1',
        name: 'home1',
        component: () => import('../../views/viewsinlayout/role1/home1'),
        meta: { breadcrumb: { level: 1, value: '首页' } },
    },
    {
        path: 'teamlist/:classType/:activetype',
        name: 'teamlist',
        component: () => import('../../views/viewsinlayout/role1/teamlist'),
        meta: { breadcrumb: { level: 11, value: '', name: '', params: '' } }
    },
    {
        path: 'teamDetail/:centerId',
        name: 'teamDetail',
        component: () => import('../../views/viewsinlayout/role1/teamDetail'),
        meta: { breadcrumb: { level: 2, value: '团队详情吧' } }
    },
    // Layout左侧菜单栏
    //有关手术室
    {
        path: "remoteopGuide",
        name: 'remoteopGuide',
        component: () => import('../../views/viewsinlayout/remoteop/remoteopGuide/remoteopGuide.vue'),
    },
    {
        path: "remoteoprManage",
        name: 'remoteoprManage',
        component: () => import('../../views/viewsinlayout/remoteop/remoteoprManage/remoteoprManage.vue'),
    },
    {
        path: "opManage/:roomid/:isroomadmin",//手术管理改名手术查询
        name: 'opManage',
        component: () => import('../../views/viewsinlayout/remoteop/remoteopGuide/opManage.vue')

    },
    {
        path: "metting",
        name: 'metting',
        component: () => import('../../views/viewsinlayout/roleCommon/metting.vue')
    },
    {
        path: "teaching",
        name: 'teaching',
        component: () => import('../../views/viewsinlayout/roleCommon/teaching.vue'),
    },
    // {
    //     path: "video",
    //     name: 'video',
    //     component: () => import('../../views/viewsinlayout/roleCommon/video.vue'),
        
    // },
    {
        path: "videoLook",
        name: 'videoLook',
        component: () => import('../../components/longTeach/video/videoLook.vue'),
    },
    // {
    //     path: "knowledge",
    //     name: 'knowledge',
    //     component: () => import('../../views/viewsinlayout/roleCommon/knowledge.vue'),
    // },
    {
        path: "knowledgeLook",
        name: 'knowledgeLook',
        component: () => import('../../components/longTeach/knowledge/knowledgeLook.vue'),
    },
    {
        path: "knowledgeDetail/:type/:id/:type0",
        // type add edit 
        // type0 0未发布 1审核中 2已通过 3未通过 4 add
        name: 'knowledgeDetail',
        component: () => import('../../components/longTeach/knowledge/knowledgeDetail.vue'),
    },
    {
        path: "knowledgeDetailLook/:type/:id/:type0",
        // type0 0查看详情 1审核中 2已通过
        name: 'knowledgeDetailLook',
        component: () => import('../../components/longTeach/knowledge/knowledgeDetailLook.vue'),
    },
]



export default { child1 }