////"roleCode": 角色类型:  0: 系统管理员 , 1: 手术医生 ，2: 专家医生  , 3: 业务员

const child0 = [
    {
        path: 'home0',
        name: 'home0',
        component: () => import('../../views/viewsinlayout/role0/home0')
    },
    // Layout左侧菜单栏
    {
        path: "manManage",
        name: 'manManage',
        component: () => import('../../views/viewsinlayout/role0/manManage.vue'),
    },
    {
        path: "teamManage",
        name: 'teamManage',
        component: () => import('../../views/viewsinlayout/role0/teamManage.vue'),
    },
    {
        path: "hospManage",
        name: 'hospManage',
        component: () => import('../../views/viewsinlayout/role0/hospManage.vue'),
    },
    {
        path: "roomManage",
        name: 'roomManage',
        component: () => import('../../views/viewsinlayout/role0/roomManage.vue'),
    },
    {
        path: "systemDay",
        name: 'systemDay',
        component: () => import('../../views/viewsinlayout/role0/systemDay.vue'),
    },
    {
        path: "notice",
        name: 'notice',
        component: () => import('../../views/viewsinlayout/role0/notice.vue'),
    },
    {
        path: "areaManage",
        name: 'areaManage',
        component: () => import('../../views/viewsinlayout/role0/areaManage.vue'),
    },
    {
        path: "myJudge",
        name: 'myJudge',
        component: () => import('../../views/viewsinlayout/role0/myExam.vue'),
    },
    {
        path: "myContent0",
        name: 'myContent0',
        component: () => import('../../views/viewsinlayout/role2/myContent.vue'),
    },
    {
        path: 'KnowledgeHome_sys',
        name: 'KnowledgeHome_sys',
        component: () => import('../../views/viewsinlayout/knowledge/KnowledgeHome_sys.vue')
    },{
        path: 'otherModelList',
        name: 'otherModelList',
        component: () => import('../../views/viewsinlayout/knowledge/otherModelList.vue')
    },
    {
        path: 'knowledgeTextDetail/:id',
        name: 'knowledgeTextDetail',
        component: () => import('../../views/viewsinlayout/knowledge/knowledgeTextDetail.vue')
    },
    {
        path: 'knowledgePersonHome_sys',
        name: 'knowledgePersonHome_sys',
        component: () => import('../../views/viewsinlayout/knowledge/knowledgePersonHome_sys.vue')
    },
    {
        path: 'knowledgeCollect_sys',
        name: 'knowledgeCollect_sys',
        component: () => import('../../views/viewsinlayout/knowledge/knowledgeCollect_sys.vue')
    },
    {//知识库类别设置
        path: 'categorySetting',
        name: 'categorySetting',
        component: () => import('../../views/viewsinlayout/knowledge/categorySetting.vue')
    },


]



export default { child0 }
