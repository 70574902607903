
const child3 = [
    {
        path: 'home3',
        name: 'home3',
        component: () => import('../../views/viewsinlayout/role0/home0')
    },
    //以下是消息模块
    //有关消息模块聊天：
    {
        path: "chat",
        name: 'chat',
        // component: () => import('../../views/viewsinlayout/message/chat'),
        component: () => import('../../views/viewsinlayout/message/remove_chat/chat.vue'),
    },
    //有关消息模块：
    {
        path: "friend",
        name: 'friend',
        // component: () => import('../../views/viewsinlayout/message/friend'),
        component: () => import('../../views/viewsinlayout/message/remove_chat/friend.vue'),
    },
    //有关消息模块：
    {
        path: "organization",
        name: 'organization',
        // component: () => import('../../views/viewsinlayout/message/organization'),
        component: () => import('../../views/viewsinlayout/message/remove_chat/origanization.vue'),
    },
    {
        path: 'notice',
        name: 'notice',
        component: () => import('../../views/viewsinlayout/message/notice'),
    },
    // systemMessage
    {
        path: 'systemMessage',
        name: 'systemMessage',
        component: () => import('../../views/viewsinlayout/message/systemMessage'),
    },
]



export default { child3 }