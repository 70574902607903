import JSEncrypt from 'jsencrypt'

// import CryptoJs from 'crypto-js';
// import jsencrypt from 'jsencrypt'

// let rsapublickey="MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsFql++V2+DFKXIGOZc7W6QCCouzTum/K\n" +
//             "dncyVSOpO6UI3ZU6z/xRc5Rz+zLvdbPqvQIiuCt0FS1eoUyCWEIZVMiPv+lr4uFAyOLufkdqSvCC\n" +
//             "xU3tKbwp12kcWHjIT7BNboCMocv6BueLsS7C/5NDVYDD8CLEmIXSCmAx/ObEOFW7MUJkKTtG/YhB\n" +
//             "dW+oMDAfTRI4OSzIU2pb7QmyCtXYlFDzlUlqqz+0FfFRsNzLTDijIsZwZHLh/RjcTvchTE40kx7w\n" +
//             "ua89g3eVxugAlAWHVDihuEG5bUGdmz6EOase1tq9IWUKNxkW/vo03deSEefG7YEA5ax5WnuvJsPE\n" +
//             "DqClmwIDAQAB";
// let crypt = new JSEncrypt();
// crypt.setKey(rsapublickey); //You can use also setPrivateKey and setPublicKey, they are both alias to setKey

// let rsaencrypt=(data)=>{
//     console.log("rsaencrypt",data);
//     console.log("rsaencrypt被调用了");
//     return crypt.encrypt(data);

//     };

// export default {
// rsaencrypt()
// }
// 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsFql++V2+DFKXIGOZc7W6QCCouzTum/K\n' +
// 'dncyVSOpO6UI3ZU6z/xRc5Rz+zLvdbPqvQIiuCt0FS1eoUyCWEIZVMiPv+lr4uFAyOLufkdqSvCC\n' +
// 'xU3tKbwp12kcWHjIT7BNboCMocv6BueLsS7C/5NDVYDD8CLEmIXSCmAx/ObEOFW7MUJkKTtG/YhB\n' +
// 'dW+oMDAfTRI4OSzIU2pb7QmyCtXYlFDzlUlqqz+0FfFRsNzLTDijIsZwZHLh/RjcTvchTE40kx7w\n' +
// 'ua89g3eVxugAlAWHVDihuEG5bUGdmz6EOase1tq9IWUKNxkW/vo03deSEefG7YEA5ax5WnuvJsPE\n' +
// 'DqClmwIDAQAB'
export default {
  install(Vue) {
    Vue.prototype.rsaencrypt = function (data) {
      if (typeof (data) === 'object')
        data = JSON.stringify(data);
         let rsapublickey =
        "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAkExwHUoNykBIukuJakJ+"
        + "WhYpN2HV/b6U7/5OyhJRQbkIyPD/nTicd2hNqjc4IiE3UYY5teXm0s9uXQE8AsmhPNz+"
        + "zjfHcty4Pq4aFbcCMUQ7sLddro0kURGeR6Jpbfowcyt595p/Z7c0T2Q07NqVLO+Tg+nF3aRHDGNg0JsJgH0LrQ6V4EKEbP35PkjVBJ+"
        + "pdZ56nVBy5Iaw26truvmj8iSO9aSwOB05YyN4hB1XxwkKjMTSz127VxSxxlNXrf3QH9P2DZll6Wf9fQ6ste2C4A/ySbGNFTFE8iagR/A6QSCAfp7GMULHic0JZbWh8h3shecb2nBGEk8RddB4WKqL9QIDAQAB"
      let crypt = new JSEncrypt()
      crypt.setKey(rsapublickey) //You can use also setPrivateKey and setPublicKey, they are both alias to setKey

      console.log('rsaencrypt', data)
      console.log('rsaencrypt被调用了')
      let result = crypt.encrypt(data);
      console.log(result);
      return result;
    }
  },
}
