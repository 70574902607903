//对称加密symmetric crypt fun
import CryptoJs from 'crypto-js';


export default{
    install(Vue){
        Vue.prototype.md5=function(data){
            let res =CryptoJs.MD5(data).toString();
            console.log("md5原data",data,"md5后data",res);
            return res;
        }
        
        Vue.prototype.aesencrypt=()=>{
            //data是字符串
            //加密的两个都先parse了，最后结果tostring
            let data1 = arguments[0];
            let key = arguments[1];
           data1 = CryptoJs.enc.Utf8.parse(data1);
          key = CryptoJs.enc.Utf8.parse(key);
          let mid = CryptoJs.AES.encrypt(data1,key,{
            mode: CryptoJs.mode.ECB,
            padding: CryptoJs.pad.Pkcs7
        });
          let res = mid.toString()
          return res;

    }

        Vue.prototype.aesdecrypt = (data,key)=>{
            ////传入的key和data是字符串，utf8parse key,但不parse data，解密后utf8 stringify data
            console.log("data,key",data,key);
             
            key = CryptoJs.enc.Utf8.parse(key);
            let midres = CryptoJs.AES.decrypt(data,key,{
                mode:CryptoJs.mode.ECB,
                padding:CryptoJs.pad.Pkcs7
            });
            let res = CryptoJs.enc.Utf8.stringify(midres);
            
            if(typeof(res)==='string'){res = JSON.parse(res);console.log("aes解密结果",res); return res;}
            return res;
        }
        Vue.prototype.aeskeygen = (len)=>{
           
                let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
                let uuid = [], i;
                let radix = chars.length;
               
                  if (len) {
                    // Compact form
                    for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random()*radix];
                    
                  } 
                 
                 
                  let res = uuid.join("")
                  
                  return res;
              
        }
 }
}