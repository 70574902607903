////"roleCode": 角色类型:  0: 系统管理员 , 1: 手术医生 ，2: 专家医生  , 3: 业务员

const child9 = [
    {
        path: 'home9',
        name: 'home9',
        component: () => import('../../views/viewsinlayout/role9/home9')
    },
    {
        path: 'movaManManage',
        name: 'movaManManage',
        component: () => import('../../views/viewsinlayout/role9/movaManManage')
    },

    {//联盟相关设置
        path: 'allianceSettings',
        name: 'allianceSettings',
        component: () => import('../../views/viewsinlayout/alliance/allianceSettings.vue')
    }, {//标签设置
        path: 'LabelSettings',
        name: 'LabelSettings',
        component: () => import('../../views/viewsinlayout/alliance/LabelSettings.vue')
    }, {//广告设置
        path: 'AdvertisingManagement',
        name: 'AdvertisingManagement',
        component: () => import('../../views/viewsinlayout/alliance/AdvertisingManagement.vue')
    }, {//推送设置
        path: 'PushManagement',
        name: 'PushManagement',
        component: () => import('../../views/viewsinlayout/alliance/PushManagement/PushManagement.vue')
    }, {//新增推送
        path: 'AddPushManagement',
        name: 'AddPushManagement',
        component: () => import('../../views/viewsinlayout/alliance/PushManagement/AddPushManagement.vue')
    }, {//商品设置
        path: 'ShoppingManage',
        name: 'ShoppingManage',
        component: () => import('../../views/viewsinlayout/alliance/ShoppingManage/ShoppingManage.vue')
    }, {//新增商品
        path: 'AddShoppingManage/:id/:isEdit/:type',
        name: 'AddShoppingManage',
        component: () => import('../../views/viewsinlayout/alliance/ShoppingManage/AddShoppingManage.vue')
    }, {//奖品设置
        path: 'PrizeSetup',
        name: 'PrizeSetup',
        component: () => import('../../views/viewsinlayout/alliance/PrizeSetup/PrizeSetup.vue')
    },{//首页设置
        path: 'homeSettings',
        name: 'homeSettings',
        component: () => import('../../views/viewsinlayout/alliance/homeSettings/homeSettings.vue')
    },

    {//问卷调查
        path: 'Questionnaire',
        name: 'Questionnaire',
        component: () => import('../../views/viewsinlayout/alliance/Questionnaire/Questionnaire.vue')
    },
    {//问卷详情
        path: 'AddQuestionnaire/id=:id/status=:status',
        name: 'AddQuestionnaire',
        component: () => import('../../views/viewsinlayout/alliance/Questionnaire/AddQuestionnaire.vue')
    },
    {//问卷详情
        path: 'QuestionnaireResult/id=:id',
        name: 'QuestionnaireResult',
        component: () => import('../../views/viewsinlayout/alliance/Questionnaire/QuestionnaireResult.vue')
    },
    {//问卷详情
        path: 'QuestionnaireDraft',
        name: 'QuestionnaireDraft',
        component: () => import('../../views/viewsinlayout/alliance/Questionnaire/QuestionnaireDraft.vue')
    },
    {//奖品发放
        path: 'GrantPrize',
        name: 'GrantPrize',
        component: () => import('../../views/viewsinlayout/alliance/GrantPrize/GrantPrize.vue')
    },
    
    
]



export default { child9 }
