
const child2 = [
        {
                path: 'home2',
                name: 'home2',
                component: () => import('../../views/viewsinlayout/role2/home2')

        },
        {
                path: 'pie',
                name: 'pie',
                component: () => import('../../plugins/stylemodules/pie.vue')
        },
        {
                path: "operateLook",
                name: 'operateLook',
                component: () => import('../../views/viewsinlayout/remoteop/operateLook/operateLook.vue'),
        },
        // Layout左侧菜单栏
        {
                path: "doctorManage",
                name: 'doctorManage',
                component: () => import('../../views/viewsinlayout/role2/doctorManage.vue'),
        },
        {
                path: "doctorWork",
                name: 'doctorWork',
                component: () => import('../../views/viewsinlayout/role2/doctorWork.vue'),
        },

        {
                path: "myContent",
                name: 'myContent',
                component: () => import('../../views/viewsinlayout/role2/myContent.vue'),
        }

]



export default { child2 }