// import state from "./state"

const actions = {
  //存cookie，存store，存sessionstorage：
  login(context, userInfo) {
    //登录的时候清空一下面包屑
    //清空一下侧边栏
    // context.commit('updatechooseitemvalue', '');
    // context.commit('clearBreadCrumb');
    
    new Promise((resolve) => {
      context.commit('updateUserInfo', userInfo);
      resolve(context);
    }).then((res) => {
      // console.log('第一次请求的结果：', res);
      return new Promise((resolve) => {
        res.commit('add');
        resolve(context);
      }).then((res) => {
        console.log('第二次请求的结果：', res);
      });
    });

    context.commit('updatechooseitemvalue','主页');
    context.commit('updateUnfold',[]);
    context.commit('clearBreadCrumb');
  },
  aesSecret(context, aesKey) {
    context.commit('updateAesKey', aesKey);
  },
  // chooseitemvalueFun(context,val){
  //   //更改chooseitemvalue并且如果是二级菜单展开这个二级菜单收掉其他展开。
  //   context.commit('updatechooseitemvalue',val);
  //   //判断是否是二级菜单
  // },
  breadcrumbFun({ commit, state }, newVal) {
    //newval添加的东西

    // let lastNode = this.$store.state.breadcrumb[this.$store.state.breadcrumb.length-1];
    // console.log("statehere,newval,lastnodeval",newVal,lastNode.value);
    /**newVal格式约定：{level:,value:,} */
    // console.log(newVal, 'newVal in actions');
    let list = state.breadcrumb||[];

    let fun = function (list, newVal) {
      // console.log('action里面的funhere');
      let lastNode = null;
      lastNode = list[list.length - 1]||null;
      // console.log('listhere_lastnode', lastNode);
      if (!lastNode) {
        list = list.concat(newVal);
        // console.log(list, 'listnew新的value有没有传过来特别是两个的时候');
        return list;
      } else if (newVal[0].level <= lastNode.level) {//需要消掉
        // console.log(list,'list需要消掉的情况');
        list.pop(); //改写递归怎么改
        return fun(list, newVal);
      } else if (newVal[0].level > lastNode.level) {
        list = list.concat(newVal);
        return list;
      }
    };

    let res = fun(list, newVal);
    // console.log(res, '这就是检验的终极成果了reslist');
    commit('updateBreadCrumb', res);
  },
};
export default actions;
