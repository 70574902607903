<template>
  <button
    :class="
      type == 1 ? 'ElisButton-type1' : type == 2 ? 'ElisButton-type2' : ''
    "
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  props: {
    text: {
      default: '确定',
    },
    type: {
      default: 1,
    },
  },
};
</script>

<style scoped>
.ElisButton-type1 {
  width: 118px;
  height: 34px;
  background: #00a8e3;
  border: 1px solid #00a8e3;
  color: white;
  opacity: 1;
  border-radius: 19px;
  cursor: pointer;
}
.ElisButton-type2 {
  width: 118px;
  height: 34px;
  border: 1px solid #00a8e3;
  border-radius: 19px;
  background-color: white;
  color: #00a8e3;
  cursor: pointer;
}
</style>