function meetingChat() {
	var lockReconnect = false;//避免重复连接
	var wsChat = null; //WebSocket的引用
	var wsUrl = ""; //这个要与后端提供的相同
	var requestNo = 10001;      //请求编号10001   前端每一次新的请求自动增长+1 如  10002
	var accountId = 0;
	var token = '';
	var _this;
	var platform = 'pc';		//平台来源（"pc","andriod","ios","ipad","phoneBrowser":手机浏览器)
	var closeFlag = false;		//主动关闭标志

	this.onHandlerMsg;

	//创建WebSocket连接
	this.createWebSocket = function (url, _accountId, _token, _platform) {
		console.log(_accountId, _token)
		_this = this;
		accountId = _accountId;
		token = _token;
		platform = _platform;
		try {
			wsUrl = url;
			wsChat = new WebSocket(wsUrl);
			// console.log("WebSocket连接是否创建")
			init();
		} catch (e) {
			if (closeFlag == false)
				reconnect(wsUrl);
		}
	}

	this.close = function () {
		closeFlag = true;
		if (wsChat != null)
			wsChat.close();
		wsChat = null;
		console.log("ws已经被关闭")
	}

	function reconnect(url) {

		if (lockReconnect) return;
		if (closeFlag == true) return;
		lockReconnect = true;
		//没连接上会一直重连，设置延迟避免请求过多
		setTimeout(function () {
			let _accountId = accountId;
			let _token = token;
			let _platform = platform;
			_this.createWebSocket(url, _accountId, _token, _platform);
			console.log("正在重连，当前时间" + new Date())
			lockReconnect = false;
		}, 5000); //这里设置重连间隔(ms)
	}

	/*********************初始化开始**********************/
	function init() {
		// 连接成功建立后响应
		wsChat.onopen = function () {
			console.log("成功连接到" + wsUrl);
			//心跳检测重置

			//if (wsUrl == 'test')
			//heartBeatTime();
		}
		// 收到服务器消息后响应
		wsChat.onmessage = onChatMsg;

		// 连接关闭后响应
		wsChat.onclose = function () {
			console.log("关闭连接");
			reconnect(wsUrl);//重连
		}
		wsChat.onerror = function () {
			console.log("error");
			reconnect(wsUrl);//重连
		};
	}
	/***************初始化结束***********************/


	function wsSendfun(content, requestCode) {

		let sendcontent = {
			...content,
			accountId: accountId,
			token: token,
			commonParam: {
				platform: platform,
				requestNo: ++requestNo,
				requestCode,
			},
		};

		//console.log(sendcontent);
		let sendmessage = JSON.stringify(sendcontent).replace(/[ ]/g, '');
		console.log('====>',
			sendcontent,
			'sendcontent',
			sendmessage,
			'sendmessage',
			requestNo,
			'requestNo'
		);
		wsChat.send(sendmessage);

	}

	function showtext(arg) {

		let re = arg.replace(
			/\\u[0-9a-z][0-9a-z][0-9a-z][0-9a-z]/g,
			function (a) {
				// console.log(a,'看下a');
				let b = a.replace(/\\u/g, '');
				let res = String.fromCharCode(parseInt(b, 16).toString(10));
				// console.log(res+'');
				return res;
			}
		);

		// window.document.write(re);
		return re;
	}

	function onChatMsg(evt) {
		let re = showtext(evt.data);
		// console.log( "转中文后：",JSON.parse(re));
		let res = JSON.parse(re);
		// console.log('收到报文：', res);
		if (_this.onHandlerMsg != null)
			_this.onHandlerMsg(res);
	}

	//发送公聊 3101
	this.send_3101 = function (friendId, messageType, messageInfo, url) {
		//
		wsSendfun(
			{
				friendId: friendId,			//接收账户id
				messageType: messageType,     //信息类型  0:文字, 1:图片
				messageInfo: messageInfo,     //发送信息
				url: url,						//资源的url地址
			},
			3101
		);
	}
	//发送私聊 3102
	this.send_3102 = function (friendId, messageType, messageInfo, url) {
		//
		wsSendfun(
			{
				friendId: friendId,			//接收账户id
				messageType: messageType,     //信息类型  0:文字, 1:图片
				messageInfo: messageInfo,     //发送信息
				url: url,						//资源的url地址
			},
			3102
		);
	}
	//发送撤销聊天信息 3103
	this.send_3103 = function (messageId) {
		//
		wsSendfun(
			{
				messageId: messageId,			//原消息id

			},
			3103
		);
	}
	//发送修改个人资料 3104
	this.send_3104 = function (name, avatar) {
		//
		wsSendfun(
			{
				name: name,			//姓名
				avatar: avatar,		//头像的url地址
			},
			3104
		);
	}
	//取历史聊天信息 3105
	this.send_3105 = function (pageNum, pageSize) {
		//
		wsSendfun(
			{
				pageNum: pageNum,			//页码，从1开始
				pageSize: pageSize,		//每页数据量
			},
			3105
		);
	}
	//取房间人员信息 3106
	this.send_3106 = function () {
		//
		wsSendfun(
			{

			},
			3106
		);
	}
	//加入黑名单 3107
	this.send_3107 = function (friendId) {
		//
		wsSendfun(
			{
				friendId: friendId,			//加入黑名单的账户id
			},
			3107
		);
	}
	//移出黑名单 3108
	this.send_3108 = function (friendId) {
		//
		wsSendfun(
			{
				friendId: friendId,			//加入黑名单的账户id
			},
			3108
		);
	}
	//查询黑名单 3109
	this.send_3109 = function () {
		//
		wsSendfun(
			{

			},
			3109
		);
	}
	//设为管理员 3201
	this.send_3201 = function (friendId) {
		//
		wsSendfun(
			{
				friendId: friendId,			//处理账户id
			},
			3201
		);
	}
	//取消管理员 3202
	this.send_3202 = function (friendId) {
		//
		wsSendfun(
			{
				friendId: friendId,			//处理账户id
			},
			3202
		);
	}
	//踢出聊天室 3203
	this.send_3203 = function (res) {
		//
		wsSendfun(
			{
				...res//friendId: friendId,			//处理账户id
			},
			3203
		);
	}
	//禁言 3204
	this.send_3204 = function (friendId) {
		//
		wsSendfun(
			{
				friendId: friendId,			//处理账户id
			},
			3204
		);
	}
	//解除禁言 3205
	this.send_3205 = function (friendId) {
		//
		wsSendfun(
			{
				friendId: friendId,			//处理账户id
			},
			3205
		);
	}
	//账户冻结 3206
	this.send_3206 = function (friendId) {
		//
		wsSendfun(
			{
				friendId: friendId,			//处理账户id
			},
			3206
		);
	}
	//封锁IP 3207
	this.send_3207 = function (friendId) {
		//
		wsSendfun(
			{
				friendId: friendId,			//处理账户id
			},
			3207
		);
	}


	// 取群聊聊天信息
	this.send_1104 = function () {
		wsSendfun(
			{
				pageNum: 1,
				pageSize: 100
			},
			1104
		);
	}

	// 1.3 信息类业务 

	this.send_1301 = function (v) {
		wsSendfun(
			{
				...v
			},
			1301
		);
	}
	this.send_1302 = function (v) {
		wsSendfun(
			{
				...v
			},
			1302
		);
	}
	// 取单聊信息
	this.send_1304 = function (v) {
		wsSendfun(
			{
				...v
			},
			1304
		);
	}
	// 取群聊聊天信息
	this.send_1305 = function (v) {
		wsSendfun(
			{
				...v
			},
			1305
		);
	}
	// 1.4聊天室管理业务
	//1.4.1.取聊天室信息（单聊）1401
	this.send_1401 = function (v) {
		wsSendfun(
			{
				...v
			},
			1401
		);
	}
	//1.4.2.取聊天室信息（群聊）1401
	this.send_1402 = function (v) {
		wsSendfun(
			{
				...v
			},
			1402
		);
	}
	//1.5.组织类业务
	//1.5.1.查询组织聊天室信息1501
	this.send_1501 = function (v) {
		wsSendfun(
			{
				...v
			},
			1501
		);
	}
	this.send_1504 = function (v) {
		wsSendfun(
			{
				...v
			},
			1504
		);
	}
	//1.1.10.更改好友分组名称1110
	this.send_1110 = function (v) {
		wsSendfun(
			{
				...v
			},
			1110
		);
	}
	//1.1.11.删除好友分组1111
	this.send_1111 = function (v) {
		wsSendfun(
			{
				...v
			},
			1111
		);
	}
	//1.1.12.好友分组信息查询1112
	this.send_1112 = function (v) {
		wsSendfun(
			{
				...v
			},
			1112
		);
	}
    //1.1.8.添加好友分组1108
	this.send_1108 = function (v) {
		wsSendfun(
			{
				...v
			},
			1108
		);
	}
	//1.1.9.变更好友分组1109
	this.send_1109 = function (v) {
		wsSendfun(
			{
				...v
			},
			1109
		);
	}
	// 创建聊天室
	this.send_1403 = function (v) {
		wsSendfun(
			{
				...v
			},
			1403
		);
	}
	this.send_1404 = function (v) {
		wsSendfun(
			{
				...v
			},
			1404
		);
	}
	this.send_1405 = function (v) {
		wsSendfun(
			{
				...v
			},
			1405
		);
	}
	this.send_1107 = function (v) {
		wsSendfun(
			{
				...v
			},
			1107
		);
	}
	this.send_1202 = function (v) {
		wsSendfun(
			{
				...v
			},
			1202
		);
	}
	this.send_1102 = function (v) {
		wsSendfun(
			{
				...v
			},
			1102
		);
	}
	this.send_1105 = function (v) {
		wsSendfun(
			{
				...v
			},
			1105
		);
	}

	this.send_1106 = function (v) {
		wsSendfun(
			{
				...v
			},
			1106
		);
	}


















































































	// 1.1 好友类业务
	this.send_1101 = function (v) {
		wsSendfun(
			{
				...v
			},
			1101
		);
	}
	this.send_1114 = function (v) {
		wsSendfun(
			{
				...v
			},
			1114
		);
	}
	this.send_1103 = function (v) {
		wsSendfun(
			{
				...v
			},
			1103
		);
	}

	// 1.2 账户类
	this.send_1203 = function (v) {
		wsSendfun(
			{
				...v
			},
			1203
		);
	}

}

export default {
	meetingChat
}