import Vue from 'vue'
// 自定义防抖
// Vue.directive('debounce', {
//     inserted: function (el, binding) {
//         let timer
//         el.addEventListener('click', () => {
//             if (timer) {
//                 clearTimeout(timer)
//             }
//             timer = setTimeout(() => {
//                 binding.value()
//             }, 2000)
//         })
//     }
// })
//调用方法 v-debounce="1000"
Vue.directive('debounce', {
    inserted(el, binding) {
        el.addEventListener('click', () => {
            if (!el.disabled) {
                el.disabled = true
                setTimeout(() => {
                    el.disabled = false
                }, binding.value || 2000)
            }
        })
    }
});

// 防抖
// const on = Vue.prototype.$on;
// Vue.prototype.$on = function (event, func) {
//     console.log(event, func);
//   let timer
//   let newFunc = func
//   if (event === 'click') {
//     newFunc = function () {
//       clearTimeout(timer)
//       timer = setTimeout(function () {
//         func.apply(this, arguments)
//       }, 500)
//     }
//   }
//   on.call(this, event, newFunc)
// }

// 节流
// const on = Vue.prototype.$on
// Vue.prototype.$on = function (event, func) {
//     // console.log(event,func);
//     let previous = 0;
//     let newFunc = func;
//     // console.log(event)
//     if (event === 'click') {
//         console.log(123);
//         newFunc = function () {
//             const now = new Date().getTime();
//             console.log(now);
//             if (previous + 1000 <= now) {
//                 func.apply(this, arguments)
//                 previous = now
//             }
//         }
//     }
//     on.call(this, event, newFunc)
// }
// 
let timeout = null // 计时器标志
Vue.prototype.$throttle = function (func, time) {
    return function () {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            func.apply(this, arguments)
        }, time)
    }
}
/*       调用方法
    this.$throttle(()=>{
      console.log('防抖和节流测试');
      },1000)()
*/






