////"roleCode": 角色类型:  0: 系统管理员 , 1: 手术医生 ，2: 专家医生  , 3: 业务员

const meeting = [
    {
        path: 'meetingDirectManage',
        name: 'meetingDirectManage',
        component: () => import('@/views/viewsinlayout/meeting/meetingDirectManage')
    },
    {
        path: 'MeetingDetail/:status/:id',
        name: 'MeetingDetail',
        component: () => import('@/views/viewsinlayout/meeting/child/MeetingDetail')
    },
    {
        path: 'MeetingPeopleManage/meetingId=:meetingId',
        name: 'MeetingPeopleManage',
        component: () => import('@/views/viewsinlayout/meeting/people_manage/PeopleManage')
    },
]



export default meeting
